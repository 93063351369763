import React from 'react';
import Layout from '../components/Layout';
import { StaticQuery, graphql, Link } from 'gatsby';
// import { Link } from 'gatsby';
import get from 'lodash/get';
import Helmet from 'react-helmet';
import SEO from 'components/SEO/SEO';
// import querystring from 'querystring';
import logger from 'utils/logger';

import { Row, Col } from 'reactstrap';
import { Fluid, Offset } from 'components/Containers';
import Fuse from 'fuse.js';

import Header from 'components/Headers/Header';
// import NavCategories from 'components/Navigation/NavCategories';
// import MediaFilters from 'components/Navigation/MediaFilters';
// import ArticleEntry from 'components/Articles/ArticleEntry';

import LoadingView from 'components/Loaders/LoadingView';
import FeaturedNews from 'components/Cards/FeaturedNews';
import CardNews from 'components/Cards/CardNews';
import CardEvent from 'components/Cards/CardEvent';
import FeaturedCase from 'components/Cards/FeaturedCase';
// import DataArticles from 'utils/articlesStaticData';
// import InfiniteScroll from 'react-infinite-scroller';

class Template extends React.Component {
  componentDidMount () {

  }
  // loadArticles (){
  //   var items = list.DataArticles(0, size).map(i => {
  //     return <myview item={i} key={i.id} />
  //   }
  // }
  render () {
    logger.template(__filename, '>', 'render()');
    const siteTitle = get(this, 'props.data.site.siteMetadata.title');
    const data = get(this, 'props.data');
    logger.gql(data);
    if (
      !data ||
      !data.allContentfulArticle
    ) { return <LoadingView />; }

    const media = data.allContentfulArticle.edges;
    const page = data.contentfulPage;

    // const mediaArticles = [];
    // const mediaCases = [];
    // media.forEach((edge) => {
    //   const article = edge.node;
    //   if (article.categories && article.categories.length > 0) {
    //     article.categories.forEach((category) => {
    //       if (category.slug === `case-studies`) {
    //         mediaCases.push(article);
    //       } else {
    //         mediaArticles.push(article);
    //       }
    //     });
    //   }
    // });
    logger.debug(`media`, media);
    const fuse = new Fuse(media, {
      keys: ['node.categories.slug']
    });
    // const mediaCases = fuse.search(`case-studies`);
    const mediaCases = data.contentfulPage.featuredCases;
    const mediaEvents = fuse.search(`events`);

    logger.debug(`mediaCases`, mediaCases);

    const mediaArticles = media.filter(el => !mediaCases.includes(el) && !mediaEvents.includes(el));
    logger.debug(`mediaArticles`, mediaArticles);

    const gutter = 8;
    const headerProps = {
      height: `30vh`,
      bgColor: `primary-500`,
      bgImage: `/img/Universiade-2017.jpg`,
      parallax: [
        {
          image: '/img/Universiade-2017.jpg',
          amount: 0.35,
          slowerScrollRate: false
        }
      ]
    };
    logger.templateEnd();
    return (
      <Layout>
        <Fluid>
          <SEO
            title={page.title}
            pathname={`/media`}
          />
          <Header {... headerProps}>
            <Offset className={`text-left`}>
              <h1 className={`fs-35em`}>{page.title}</h1>
            </Offset>
          </Header>
          <nav className={`nav-categories ptb-10em fs-08em prl-10em bg-indigo-700`}>
            <Fluid>
              <Offset>
                <Row>
                  <Col>
                    <Link to={`/media/articles`} className={`btn btn-link ptb-0 pl-0 inline tt-none fs-11em ls-10em color-indigo-200 color-white-hover`}>Articles</Link>
                    <Link to={`/media/articles?category=case-studies`} className={`btn btn-link ptb-0 pl-0 inline tt-none fs-11em ls-10em color-indigo-200 color-white-hover`}>Case Studies</Link>
                    {/*<Link to={`/media/brand-guidelines`} className={`btn btn-link ptb-0 pl-0 inline tt-none fs-11em ls-10em color-indigo-200 color-white-hover`}>Media Guidelines</Link>*/}
                  </Col>
                </Row>
              </Offset>
            </Fluid>
          </nav>
          <section className={`media bg-gray-cold-100 pt-20em pb-30em`}>
            <Offset>
              <Row>
                <Col md={6}>
                  <Row className={`pb-10em`}>
                    <Col xs={12}>
                      <h1 className={`fw-300 fs-20em`}>Upcoming Events</h1>
                      <hr className="alpha-40" />
                    </Col>
                  </Row>
                  <Row className={`pb-10em`}>
                    { mediaEvents && mediaEvents.slice(0, 2).map((edge, i) => {
                      const article = edge.node;
                      const excerpt = article.excerpt ? article.excerpt.excerpt : article.content ? `${article.content.content.substring(0, 246)}...` : `...`;
                      return (
                        <Col xs={12} key={`newsArticles-${article.id}`} className={`prl-${gutter / 2}px pb-${gutter}px`}>
                          <CardEvent {... article} />
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
                <Col md={6}>
                  <Row className={`pb-10em`}>
                    <Col xs={12}>
                      <h1 className={`fw-300 fs-20em`}>Latest News</h1>
                      <hr className="alpha-40" />
                    </Col>
                  </Row>
                  <Row className={`featured-cards`}>
                    { mediaArticles && mediaArticles.slice(0, 1).map((edge, i) => {
                      const article = edge.node;
                      return (
                        <Col key={`featuredArticles-${article.id}`} xs={12} className={`prl-${gutter / 2}px pb-${gutter}px`}>
                          <FeaturedNews {... article} />
                        </Col>
                      );
                    })}
                  </Row>
                  <Row className={`fluid news-cards`}>
                    { mediaArticles && mediaArticles.slice(1, 3).map((edge, i) => {
                      const article = edge.node;
                      const excerpt = article.excerpt ? article.excerpt.excerpt : article.content ? `${article.content.content.substring(0, 246)}...` : `...`;
                      return (
                        <Col md={6} key={`newsArticles-${article.id}`} className={`prl-${gutter / 2}px pb-${gutter}px`}>
                          <CardNews {... article} />
                        </Col>
                      );
                    })}
                  </Row>
                  <Row>
                    <Col className={`text-center prl-${gutter / 2}px pb-${gutter}px`}>
                      <Link to={`/media/articles`} className={`btn btn-block btn-lg btn-indigo-500`}>More News</Link>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Offset>
          </section>
          <section className={`pt-20em pb-${gutter}px prl-${gutter / 2}px`}>
            <Offset>
              <Row className={`pb-10em`}>
                <Col xs={12}>
                  <h1 className={`fw-300 fs-20em`}>Case Studies</h1>
                  <hr className="alpha-40" />
                </Col>
              </Row>
              <Row className={`featured-cases pb-${gutter}px`}>
                { mediaCases && mediaCases.map((article, i) => {
                  // const article = edge.node;
                  const f = i === 0;
                  const lgCol = f ? 12 : 4;
                  return (
                    <Col
                      key={`featuredCases-${article.id}`}
                      lg={lgCol}
                      className={`prl-${gutter / 2}px pb-${gutter}px`}
                    >
                      <FeaturedCase
                        {... article}
                        first={f}
                      />
                    </Col>
                  );
                })}
              </Row>
              <Row>
                <Col className={`text-center pb-${gutter}px`}>
                  <Link to={`/media/articles?category=case-studies`} className={`btn btn-lg btn-outline-gray-warm-200 color-gray-cold-400 color-gray-cold-600-hover`}>Discover more stories</Link>
                </Col>
              </Row>
            </Offset>
          </section>
        </Fluid>
      </Layout>
    );
  }
}

export const query = graphql`
  query($slug: String!){
    site {
      siteMetadata {
        title
      }
    }
    allContentfulArticle(sort: { fields: [createdAtLegacy], order: DESC}){
      edges{
        node {
          id
          ... Article
        }
      }
    }
    allContentfulCategory( filter: { contentTypes: { in: ["articles"] } } ){
      edges{
        node {
          id
          ... Category
        }
      }
    }
    contentfulPage(slug: {eq: $slug}){
      title
      featuredCases{
        id
        ... Article
      }
    }
  }
`;

export default Template;

// export default ({ children }) => (
//   <StaticQuery
//     query={query}
//     render={data => (
//       <>
//         <Helmet title={`${`Media`}`} />
//         <Template data={data}>
//           {children}
//         </Template>
//       </>
//     )}
//   />
// );
